<template>
        <main-template>
            <template v-slot:headerLottieDark>
                <dotlottie-player :src="getDarkLogo()"
                                  background="transparent" speed="1"  style="width: 450px; height: 80px" direction="1" playMode="normal"  no-autoplay></dotlottie-player>
            </template>
            <template v-slot:headerLottieLight>
                <dotlottie-player :src="getLightLogo()"
                                  background="transparent" speed="1"  style="width: 450px; height: 80px" direction="1" playMode="normal"  no-autoplay></dotlottie-player>
            </template>
            <template v-slot:header>QA Management</template>
            <template v-slot="slotProps">
            <fetch-siren-root ref="sirenRoot" :linkName='linkName' :link="link">

                <div slot-scope="{ response }" class="flex flex-grow overflow-hidden">

                        <div class="flex w-full overflow-hidden">
                            <div class="p-2 h-full">
                                <qa-navigation :links="slotProps.links"></qa-navigation>
                            </div>
                            <div class="flex w-full h-full p-2 overflow-hidden">
                                <div class="rounded-xl bg-v3-white-100 w-full h-full margin-10 dark:bg-v3-gray-900 overflow-hidden" :class="{'grid grid-cols-3': (details || loadingDetails) && !hideTable}">
                                    <loading v-if="!response"></loading>
                                    <template v-else>
                                        <div class="p-2 overflow-y-auto h-full" :class="{'hidden': hideTable}">
                                            <qa-categories-pagination ref="voucherList" :response="response" :entities="getVouchers(response)" @setDetails="showDetails" @setLoading="setLoading" @altViewClicked="altViewClicked">
                                                <template v-slot:additional-buttons>
                                                    <template v-for="(action, index) in response.body.actions.filter(action => action.name === 'create-template')">
                                                        <form-action :response="response" class="ml-2" additionalButtonClasses="my-2 w-full" :action="action[1]" :actionKey="action[0]" :redirectOnSuccess="true" :onSuccessPath="$router.currentRoute.fullPath"></form-action>
                                                    </template>
                                                </template>
                                            </qa-categories-pagination>
                                        </div>
                                        <single-qa-category-details ref="voucherDetails" :details="details" :loadingDetails="loadingDetails" :hideTable="hideTable" @setLoading="setLoading" @getDetails="getDetails" @hideTable="toggleHideTable"></single-qa-category-details>
                                    </template>
                                </div>
                            </div>
                        </div>
                </div>
            </fetch-siren-root>
            </template>
        </main-template>
</template>

<script>
import FetchSirenRoot from '@/components/FetchSirenRoot';
import Loading from '@/components/Loading';
import MainTemplate from '@/v3/templates/MainTemplate';
import {mapGetters} from "vuex";
import Siren from "super-siren";
import VoucherNavigation from "@/v3/components/navigation/VoucherNavigation.vue";
import SingleVoucherDetails from "@/v3/pages/SingleVoucherDetails.vue";
import FormAction from "@/v3/components/forms/FormAction.vue";
import CreatePaymentVoucherForm from "@/v3/components/forms/CreatePaymentVoucherForm.vue";
import QaCategoriesPagination from "@/v3/components/pagination/QaCategoriesPagination.vue";
import SingleQaCategoryDetails from "@/v3/pages/SingleQaCategoryDetails.vue";
import QaNavigation from "@/v3/components/navigation/QaNavigation.vue";

export default {
    inject: ["$validator"],
    components: {
        QaNavigation,
        SingleQaCategoryDetails,
        QaCategoriesPagination,
        FormAction,
        SingleVoucherDetails,
        VoucherNavigation,
        FetchSirenRoot,
        Loading,
        MainTemplate,
        CreatePaymentVoucherForm

    },
    data() {
        return {
            slotProps: null,
            users: null,
            details: null,
            detailsOpen: false,
            loadingDetails: false,
            entities: null,
            hideTable: false
        }
    },
    computed: {
        ...mapGetters({
            accessToken: "getAccessToken",
            user: "getUser"
        }),
        linkName() {
            return null;
        },
        link(){
            // if(Object.keys(this.$route.query).length === 0){
            //     return '/orders';
            // }
            return this.$route.fullPath.replace('/v3','')
        }

    },
    mounted() {
        let follow = Siren.Helper.follow;
    },
    methods: {
        getVouchers(response) {
            if (!response) {
                return null;
            }
            return response.body.entities.filter(entity => entity.rels.contains('template'));
        },
        showDetails(details){
            this.$setLaravelValidationErrorsFromResponse('');
            this.details = details;
            this.loadingDetails = false;
        },
        setLoading(){
            this.$refs.voucherDetails.editing = false;
            this.$refs.voucherDetails.editingQuestions = false;
            this.$refs.voucherDetails.editingPaymentTypes = false;
            this.$refs.voucherDetails.editingPreCheckQuestions = false;
            this.$refs.voucherDetails.tabOpen = 'details';
            this.details = null;
            this.loadingDetails = true;
        },
        getDetails(entity){
            this.$refs.voucherList.getDetails(entity);
        },
        toggleHideTable(){
            this.hideTable = !this.hideTable;
        },
        altViewClicked(){
            this.hideTable = false;
            this.details = null;
        },
        voucherCreated(res){
            this.$refs.sirenRoot.load();
            let entity = {'entity': res.body};
            this.$refs.voucherList.openDetails(entity);
        },
        getDarkLogo()
        {
            return process.env.VUE_APP_API_URL + "/api/json/lottie?type=qa_dark"
        },
        getLightLogo()
        {
            return process.env.VUE_APP_API_URL + "/api/json/lottie?type=qa_light"
        }

    }
}
</script>

